/*
 * Application global variables.
 */

// Set Font Awesome font path
// $fa-font-path: "~font-awesome/fonts";
//@import url('https://fonts.googleapis.com/css?family=Open+Sans');
// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss
//
// Color system
//
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #49505c;
$gray-800: #343a40;
$gray-900: #212529;
$bg-gray: #272c42;
$bg-usblick: #F0EFF4;
$black: #000;
$blue: #0073dd;
$dark-blue: #00132E;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #da0268;
$red:#e93a55;
$usblick: #B70D35;
$orange: rgb(255, 102, 0);
$yellow: #ffc107;
$green: rgb(32, 201, 151);
$green-usblick: #00A680;
$teal: #20c997;
$cyan: #17a2b8;
$star: #C4BE30;
$theme-colors: ( primary: $blue, secondary: $gray-600, success: $green, info: $cyan, warning: $yellow, danger: $red, light: $gray-100, dark: $gray-700, usblick: $red);
// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.


$tooltip-color: $black !important;
$tooltip-bg: $white !important;
//$tooltip-opacity: 1 !important;
//$link-color:                theme-color("secondary") !default;
$link-color:                #6c757d !default;
$link-decoration:           none !default;
$link-hover-color:          darken($link-color, 15%) !default;

//NEW COLORS

$color-redActive: #d6385f;
$color-redOver: #bd375f;
$color-green: #00cb97;
$color-dark-1: #393939;
$color-dark-2: #272c42;
$color-dark-yellow: #ff9d00;
$color-yellow: #f8cb53;
$color-dark-blue: #0d347d;

// STATUS COLORS

// PARTIAL RESERVE
$color-partial-reserve-1: #1f2643;
$color-partial-reserve-2: #02d498;
// RESERVE
$color-reserve-1: #02d498;
$color-reserve-2: #000000;
// SAVE
$color-save-1: #1f2643;
$color-save-2: #ffffff;
// DENEGADA
$color-denegada-1: #f6ce16;
$color-denegada-2: #1f2643;
// CANCELADA
$color-cancelada-1: #ec0062;
$color-cancelada-2: #ffffff;
// LOCKED
$color-locked-1: #ec0062;
$color-locked-2: #1f2643;
// PAY USBLICK
$color-pay-usblick-1: #0f347d;
$color-pay-usblick-2: #02d498;
// PAY PROVIDER
$color-pay-provider-1: #646464;
$color-pay-provider-2: #02d498;
// FACTURADO
$color-facturado-1: #02d498;
$color-facturado-2: #ffffff;
// PENDING APPROVE PAY
$color-pending-approve-pay-1: #646464;
$color-pending-approve-pay-2: #f6ce16;
// COMPLETED
$color-completed-1: #02d498;
$color-completed-2: #646464;
