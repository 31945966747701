/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

// Social Colors
$facebook: #3b5998;
$twitter: #00aced;
$linkedin: #4875b4;
$google-plus: #d34836;
$flickr: #ff0084;
$tumblr: #32506d;
$xing: #026466;
$github: #4183c4;
$html5: #e34f26;
$openid: #f78c40;
$stack-overflow: #fe7a15;
$youtube: #b00;
$css3: #0170ba;
$dribbble: #ea4c89;
$google-plus: #bb4b39;
$instagram: #517fa4;
$pinterest: #cb2027;
$vk: #45668e;
$yahoo: #400191;
$behance: #1769ff;
$dropbox: #007ee5;
$reddit: #ff4500;
$spotify: #7ab800;
$vine: #00bf8f;
$foursquare: #1073af;
$vimeo: #aad450;
// Navbar
$navbar-height: 55px;
$navbar-bg: #ffffff;
$navbar-border: ( bottom: ( size: 1px, style: solid, color: $border-color));
$navbar-brand-width: 155px;
$navbar-brand-bg: #ffffff;
$navbar-brand-logo: url('../img/logo/logo.png');
$navbar-brand-logo-size: 155px auto;
$navbar-brand-border: none;
//$navbar-brand-border: ( bottom: ( size: 1px, style: solid, color: $border-color));
$navbar-color: rgba(233,
233,
233,
1);
$navbar-hover-color: rgba(0, 161, 67, 0.9);
$navbar-disabled-color: rgba(221,
40,
40,
0.15);
