/* @font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?4l4am2');
  src:  url('../fonts/icomoon.eot?4l4am2#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?4l4am2') format('truetype'),
    url('../fonts/icomoon.woff?4l4am2') format('woff'),
    url('../fonts/icomoon.svg?4l4am2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
} */

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-check-false:before {
  content: "\e967";
  color: #b70d35;
}
.icon-check-true:before {
  content: "\e968";
  color: #249a07;
}
.icon-icon-close:before {
  content: "\e904";
}
.icon-send-mail:before {
  content: "\e905";
}
.icon-airplane-arrive:before {
  content: "\e906";
}
.icon-airplane-departure:before {
  content: "\e907";
}
.icon-icon-tickets .path1:before {
  content: "\e908";
  color: rgb(0, 0, 0);
}
.icon-icon-tickets .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-icon-tickets .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path5:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path6:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path7:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path8:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path9:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path10:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path11:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path12:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path13:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path14:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path15:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-icon-tickets .path16:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-door:before {
  content: "\e91a";
}
.icon-ai:before {
  content: "\e91c";
}
.icon-bag:before {
  content: "\e91d";
}
.icon-asiento:before {
  content: "\e91e";
}
.icon-home:before {
  content: "\e91f";
}
.icon-car-aa:before {
  content: "\e920";
}
.icon-car-seat-icon:before {
  content: "\e921";
}
.icon-car-door-icon:before {
  content: "\e922";
}
.icon-pthemes:before {
  content: "\e923";
}
.icon-power:before {
  content: "\e927";
}
.icon-minibus:before {
  content: "\e928";
}
.icon-vespa:before {
  content: "\e929";
}
.icon-users:before {
  content: "\e92a";
}
.icon-flech:before {
  content: "\e92b";
}
.icon-dot-tripadvisor:before {
  content: "\e92c";
}
.icon-dot-tripadvisor-clean:before {
  content: "\e92d";
}
.icon-dot-tripadvisor-frac:before {
  content: "\e92e";
}
.icon-find-user:before {
  content: "\e92f";
}
.icon-restaurant-2:before {
  content: "\e930";
}
.icon-separadores:before {
  content: "\e931";
}
.icon-swimming:before {
  content: "\e932";
}
.icon-valet-parking:before {
  content: "\e933";
}
.icon-wi-fi:before {
  content: "\e934";
}
.icon-restaurant:before {
  content: "\e935";
}
.icon-up-and-down:before {
  content: "\e936";
}
.icon-airplane:before {
  content: "\e937";
}
.icon-map-distrac:before {
  content: "\e938";
}
.icon-link:before {
  content: "\e93a";
}
.icon-add-new-document:before {
  content: "\e93c";
}
.icon-calendar:before {
  content: "\e93d";
}
.icon-click:before {
  content: "\e93e";
}
.icon-destination:before {
  content: "\e941";
}
.icon-internet:before {
  content: "\e943";
}
.icon-train-front:before {
  content: "\e944";
}
.icon-train:before {
  content: "\e945";
}
.icon-user-silhouette:before {
  content: "\e94b";
}
.icon-world-wide-web:before {
  content: "\e94c";
}
.icon-separador:before {
  content: "\e94d";
}
.icon-small-calendar:before {
  content: "\e94e";
}
.icon-corazon:before {
  content: "\e94f";
}
.icon-menor:before {
  content: "\e950";
}
.icon-papelera:before {
  content: "\e951";
}
.icon-lupa:before {
  content: "\e952";
}
.icon-airplane_45:before {
  content: "\e953";
}
.icon-airplane_90:before {
  content: "\e954";
}
.icon-disney:before {
  content: "\e955";
}
.icon-seguro:before {
  content: "\e956";
}
.icon-maletas:before {
  content: "\e957";
}
.icon-actividades_exterior:before {
  content: "\e958";
}
.icon-itinerary:before {
  content: "\e959";
}
.icon-map-marker:before {
  content: "\e95a";
}
.icon-alojamiento:before {
  content: "\e95b";
}
.icon-bus:before {
  content: "\e95c";
}
.icon-car:before {
  content: "\e95d";
}
.icon-multiple-users-silhouette:before {
  content: "\e95e";
}
.icon-users-silhouette:before {
  content: "\e95f";
}
.icon-moon-phase-outline:before {
  content: "\e960";
}
.icon-hotel:before {
  content: "\e961";
}
.icon-pencil:before {
  content: "\e962";
}
.icon-placeholder:before {
  content: "\e963";
}
.icon-crucero:before {
  content: "\e964";
}
.icon-estrella:before {
  content: "\e965";
}
.icon-flecha:before {
  content: "\e966";
}
.icon-home2:before {
  content: "\e900";
}
.icon-home22:before {
  content: "\e901";
}
.icon-home3:before {
  content: "\e902";
}
.icon-office:before {
  content: "\e903";
}
.icon-image:before {
  content: "\e90d";
}
.icon-images:before {
  content: "\e90e";
}
.icon-connection:before {
  content: "\e91b";
}
.icon-file-empty:before {
  content: "\e924";
}
.icon-files-empty:before {
  content: "\e925";
}
.icon-file-text2:before {
  content: "\e926";
}
.icon-ticket:before {
  content: "\e939";
}
.icon-coin-dollar:before {
  content: "\e93b";
}
.icon-credit-card:before {
  content: "\e93f";
}
.icon-calculator:before {
  content: "\e940";
}
.icon-phone:before {
  content: "\e942";
}
.icon-pushpin:before {
  content: "\e946";
}
.icon-location:before {
  content: "\e947";
}
.icon-location2:before {
  content: "\e948";
}
.icon-compass:before {
  content: "\e949";
}
.icon-compass2:before {
  content: "\e94a";
}
.icon-user:before {
  content: "\e971";
}
.icon-users2:before {
  content: "\e972";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-user-minus:before {
  content: "\e974";
}
.icon-user-check:before {
  content: "\e975";
}
.icon-user-tie:before {
  content: "\e976";
}
.icon-gift:before {
  content: "\e99f";
}
.icon-switch:before {
  content: "\e9b6";
}
.icon-download2:before {
  content: "\e9c5";
}
.icon-download3:before {
  content: "\e9c7";
}
.icon-bookmark:before {
  content: "\e9d2";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-loop:before {
  content: "\ea2d";
}
.icon-loop2:before {
  content: "\ea2e";
}
.icon-shuffle:before {
  content: "\ea30";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-radio-checked2:before {
  content: "\ea55";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-share:before {
  content: "\ea7d";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-mail4:before {
  content: "\ea86";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-firefox:before {
  content: "\eada";
}
.icon-IE:before {
  content: "\eadb";
}
.icon-edge:before {
  content: "\eadc";
}
.icon-safari:before {
  content: "\eadd";
}
.icon-opera:before {
  content: "\eade";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-file-excel:before {
  content: "\eae2";
}
