/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

//  :root {
//    --main-color-1: color1;
//    --main-color-1-rgb: color1Rgb;
// }


// Theme variables, must be included before the libraries to allow overriding defaults
@import "~theme/theme-variables.scss";
// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/bootstrap";

@import "~font-awesome/scss/font-awesome.scss";
// Theme customization
@import "theme/theme";
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Droid+Sans');
// @import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Poppins');
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';




// toats
// regular style toast
@import '~ngx-toastr/toastr';

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
// should be after your bootstrap imports, it uses bs4 variables, mixins, functions
@import '~ngx-toastr/toastr-bs4-alert';

// if you'd like to use it without importing all of bootstrap it requires
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';




// FONT POPPINS
  // @font-face {
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 100;
  //   src: url('./assets/fonts/Poppins/Poppins-Thin.ttf');
  // }

  // @font-face {
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 300;
  //   src: url('./assets/fonts/Poppins/Poppins-Light.ttf');
  // }

  // @font-face {
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 400;
  //   src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
  // }

  // @font-face {
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 500;
  //   src: url('./assets/fonts/Poppins/Poppins-Medium.ttf');
  // }

  // @font-face {
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 600;
  //   src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf');
  // }

  // @font-face {
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 700;
  //   src: url('./assets/fonts/Poppins/Poppins-Bold.ttf');
  // }

  // @font-face {
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 800;
  //   src: url('./assets/fonts/Poppins/Poppins-ExtraBold.ttf');
  // }

  // @font-face {
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 900;
  //   src: url('./assets/fonts/Poppins/Poppins-Black.ttf');
  // }

  // @font-face {
  //   font-family: 'Poppins';
  //   font-style: normal;
  //   font-weight: 400;
  //   src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
  // }

  // // FONT LATO
  // @font-face {
  //   font-family: 'Lato';
  //   font-style: normal;
  //   font-weight: 100;
  //   src: url('./assets/fonts/Lato/Lato-Thin.ttf');
  // }

  // @font-face {
  //   font-family: 'Lato';
  //   font-style: normal;
  //   font-weight: 300;
  //   src: url('./assets/fonts/Lato/Lato-Light.ttf');
  // }

  // @font-face {
  //   font-family: 'Lato';
  //   font-style: normal;
  //   font-weight: 400;
  //   src: url('./assets/fonts/Lato/Lato-Regular.ttf');
  // }

  // @font-face {
  //   font-family: 'Lato';
  //   font-style: normal;
  //   font-weight: 700;
  //   src: url('./assets/fonts/Lato/Lato-Bold.ttf');
  // }

  // @font-face {
  //   font-family: 'Lato';
  //   font-style: normal;
  //   font-weight: 900;
  //   src: url('./assets/fonts/Lato/Lato-Black.ttf');
  // }

// FONT OPEN SANS
// @font-face {
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 300;
//   src: url('./assets/fonts/Open_Sans/OpenSans-Light.ttf');
// }

// @font-face {
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 400;
//   src: url('./assets/fonts/Open_Sans/OpenSans-Regular.ttf');
// }

// @font-face {
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 600;
//   src: url('./assets/fonts/Open_Sans/OpenSans-SemiBold.ttf');
// }

// @font-face {
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 700;
//   src: url('./assets/fonts/Open_Sans/OpenSans-Bold.ttf');
// }

// @font-face {
//   font-family: 'Open Sans';
//   font-style: normal;
//   font-weight: 800;
//   src: url('./assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf');
// }

//@import url('http://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css');

@import "./assets/fonts/style.css";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// Estilos para Rating
@import '~ngx-bar-rating/themes/br-default-theme';
@import '~ngx-bar-rating/themes/br-fontawesome-o-theme';
@import '~ngx-bar-rating/themes/br-fontawesome-theme';
@import '~ngx-bar-rating/themes/br-horizontal-theme';
@import '~ngx-bar-rating/themes/br-vertical-theme';

body, html {
  margin: 0;
  padding: 0;
  position: relative;
  font-family: 'Open Sans', sans-serif,'Droid Sans', 'Montserrat', 'Lato', 'Poppins';
  // background-color: #f0edf4;
}

body{
  overflow-x: hidden;
}

.MultiCarousel { float: left; overflow: hidden; padding: 15px; width: 100%; position:relative; }
    .MultiCarousel .MultiCarousel-inner { transition: 1s ease all; float: left; }
        .MultiCarousel .MultiCarousel-inner .item { float: left;}
        .MultiCarousel .MultiCarousel-inner .item > div { text-align: center; padding:10px; margin:10px; background:#f1f1f1; color:#666;}
    .MultiCarousel .leftLst, .MultiCarousel .rightLst { position:absolute; border-radius:50%;top:calc(50% - 20px); }
    .MultiCarousel .leftLst { left:0; }
    .MultiCarousel .rightLst { right:0; }

        .MultiCarousel .leftLst.over, .MultiCarousel .rightLst.over { pointer-events: none; background:#ccc; }


.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {

    background-color: rgba(0, 0, 0, 0.9);

}


.carouseller .carouseller__wrap {
 position: static !important;
}

//para title-header del ngx-bootstrap
// .customClass{
//   background-color: $dark-blue !important;
//   color: #fff !important;
// }

///  ngx-daterangepicker-material
///
///
// .md-drppicker td.end-date, .md-drppicker td.start-date {
//   border-radius: 50% !important;
// }

// .md-drppicker td.in-range.available:first-of-type {
//   border-top-left-radius: 5px !important;
//   border-bottom-left-radius: 5px !important;
// }

// /* Seleccionar el último elemento */
// .md-drppicker td.in-range.available:last-of-type {
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
// }



.md-drppicker.shown {
  margin-top: 0px !important;
}

.md-drppicker td {
  color: #6c757d52;
}
/*
.md-drppicker td.active {
  background-color: #da0268 !important;
  color: #fff !important;
} */

.md-drppicker td.active {
  // background-color: #49505c ;
  background: #00E2A0 !important;
  border-color: transparent !important;
  color: #fff !important;
}


.md-drppicker td.off, .md-drppicker td.off.end-date, .md-drppicker td.off.in-range, .md-drppicker td.off.start-date {
  background-color: #fff !important;
}



.md-drppicker td, .md-drppicker th {
  border: none !important;
}

.md-drppicker .calendar {
  max-width: unset !important;
}

.md-drppicker .calendar td, .md-drppicker .calendar th {
  padding: 9px !important;
}

.md-drppicker td.off, .md-drppicker td.off.end-date, .md-drppicker td.off.in-range, .md-drppicker td.off.start-date {
  color: #e9ecef !important;
 }

 .md-drppicker .calendar-time .select .select-item {
 /* display: inline-block;
  width: auto;
  position: relative;
  font-family: inherit;
  background-color: #e1358600; */
  padding: 19px 19px 17px 19px !important;
  font-size: 21px !important;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid rgba(0,0,0,.12) !important;
}

.md-drppicker.ltr {
  width: 550px !important;
}

.md-drppicker .btn, .md-drppicker .btn:focus, .md-drppicker .btn:hover {
  background-color: #00d397  !important;
}

// tr.ng-star-inserted td.in-range.available {
//   background: #8ef7d77d;
// }



// tr.ng-star-inserted td.in-range.available::before {
//   content: '';
//   display: block;
//   position: absolute;
//   top: 0;
//   left: -18px;
//   z-index: -1;
//   width: 100%;
//   height: 100%;
//   // border-radius: 50% 0 0 50%;
//   background: #33e8b34f;
// }

// tr.ng-star-inserted td.in-range.available::after {
//   content: '';
//   display: block;
//   position: absolute;
//   top: 0;
//   left: -18px;
//   z-index: -1;
//   width: 100%;
//   height: 100%;
//   // border-radius: 50% 0 0 50%;
//   background: #33e8b34f;
// }

// td.available.ng-star-inserted.active.start-date::before {
//   content: '';
//   display: block;
//   position: absolute;
//   top: 0;
//   left: -18px;
//   z-index: -1;
//   width: 100%;
//   height: 100%;
//   border-radius: 50% 0 0 50%;
//   background: #33e8b34f;
// }}

// div.calendar.left.ng-star-inserted div.calendar.table {
//   margin-right: 30px;
// }

div.calendar.right.ng-star-inserted {
  margin-left: 0.6250em !important;
}

div.md-drppicker.drops-down-auto.ltr.shown.double {
  width: 725px !important;
  left: 146px !important;
  margin-top: 5px !important;
  border-radius: 25px;
  padding-bottom: 20px;
  padding-left: 40px;
}

thead tr th.month {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 700;
  color: black;
}

tr.week-days th.ng-star-inserted span {
  font-family: 'Poppins';
  font-size: 11px;
  font-weight: 800;
  color: black;
}

thead tr.week-days th.ng-star-inserted::after {
  content: '';
  display: block;
  position: relative;
  width: 25px;
  z-index: -1;

}

tr.ng-star-inserted td.off.disabled {
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 600;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}


// div.md-drppicker.drops.down.auto.ltr.shown.double div.calendar.right.ng-star-inserted {
//   margin-left: 4.375em;
// }

tr.ng-star-inserted td.available.ng-star-inserted,
tr.ng-star-inserted td.today.available,
tr.ng-star-inserted td.available {
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 600;
  color: black;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

// tr.ng-star-inserted td.available.ng-star-inserted span,
// tr.ng-star-inserted td.today.available span,
// tr.ng-star-inserted td.available span {
//   margin-left: 15px;
// }

tr.ng-star-inserted td.end-date.in-range.avilable::after {
  background: white !important;
}

tr.ng-star-inserted td.active.start-date.available::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 50%;
  height: 100%;
  // background: rgba(var(--main-color-1-rgb), 0.3);
  background: #33e8b34f;
}

tr.ng-star-inserted td.active.end-date.available::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 50%;
  height: 100%;
  // background: rgba(var(--main-color-1-rgb), 0.3);
  background: #33e8b34f;
}

.md-drppicker td.in-range {
  // background: rgba(var(--main-color-1-rgb), 0.3) !important;
  background: #33e8b34f !important;
}

tr.ng-star-inserted td.today.active.start-date.end-date.in-range.avilable.ng-star-inserted::before {
  background: white;
}

tr.ng-star-inserted td.today.active.start-date.end-date.in-range.avilable.ng-star-inserted::after {
  background: white;
}

tr.ng-star-inserted td.off.active.start-date.available::after {
  background-color: white !important;
}

tr.ng-star-inserted td.off.active.end-date.available::before {
  background-color: white !important;
}

tr.ng-star-inserted td.today.active.start-date.end-date.in-range.available.ng-star-inserted::before {
  background: white !important;
  position: absolute;
  left: -16px;
  width: 1%;
}


tr.ng-star-inserted td.today.active.start-date.end-date.in-range.available.ng-star-inserted::after {
  background: white !important;
    position: absolute;
    left: -16px;
    width: 1%;
}

// .md-drppicker td.active {
//   background: #00E2A0 !important;
// }

// tr.ng-star-inserted td.active.end-date.in-range.available,
// tr.ng-star-inserted td.weekend.active.start-date.avilable {
//   border-radius: 50% ;
//   background: #00E2A0 !important;
// }


.md-drppicker td.start-date,
.md-drppicker td.end-date {
  border-radius: 50% !important;
  // background: var(--main-color-1) !important;
  background: #00E2A0 !important;
}
 /*.md-drppicker td.start-date {
  border-radius: 2em 2em 2em 2em !important;
}
.md-drppicker td.end-date {
  border-radius: 2em 2em 2em 2em !important;
} */

/////  .ngx-slider

.ngx-slider .ngx-slider-pointer {
  cursor: pointer !important;
  width: 20px !important;
  height: 20px !important;
  top: -9px !important;
  background-color: #00E2A0 !important;
  z-index: 3 !important;
  border-radius: 16px !important;
}

.ngx-slider .ngx-slider-selection {
  background-color: #00E2A0 !important;
}

.ngx-slider .ngx-slider-bubble {
  cursor: default  !important;
  bottom: 19px  !important;
  padding: 6px 7px  !important;
  font-size: 12px  !important;
}


.ngx-slider .ngx-slider-pointer:after {
  content: '' !important;
  width: 8px !important;
  height: 8px !important;
  position: absolute !important;
  top: 6px !important;
  left: 6px !important;
  border-radius: 4px !important;
  background: #ffffff;
}
/////  fin .ngx-slider

///////  Mapa   ///////


/////////////////////////////////////////////////

.tooltip-one {
  margin: 0 auto;
}

/* EMPIEZA AQUÍ */

.tooltip-one {
  padding: 3px 3px  !important;
  background: #00d097  !important;
  position: relative  !important;
  min-width: 50px  !important;
  max-width: 82px  !important;
  border-radius: 5px  !important;
  text-align: center  !important;
  filter: drop-shadow(0 3px 5px #ccc)  !important;
  line-height: 1.5  !important;
  font-size: 12px !important;
  color: #ffffff !important;
}

.tooltip-one:after {
  content: ""  !important;
  position: absolute  !important;
  bottom: -6px !important;
  left: 50%  !important;
  margin-left: -7px  !important;
  width: 10px  !important;
  height: 10px  !important;
  background: #00d097  !important;
  transform: rotate(45deg) !important;
}


//  .ngx-pagination .current {
//     width: 32px !important;
//     height: 32px !important;
//     border-radius: 50% !important;
//     background-color: #232121 !important;
//     // display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 0px 7.5px;
//     &:hover{
//         cursor: pointer !important;
//         background-color: #232121 !important;
//         .pagination-link{
//             color: #ffffff !important;
//         }
//     }
//     .pagination-link{
//         font-size: 14px !important;
//         letter-spacing: 0px !important;
//         line-height: 20px !important;
//         color: #232222 !important;
//         font-weight: 500 !important;
//         font-family: "Poppins" !important;
//         text-decoration: none !important;
//     }



//  }

 .ngx-pagination .current {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  padding: 0.1875rem 0.625rem;
  background: #000000 !important;
  border-radius: 50%;
  cursor: default;
  color: #fefefe;
}

.ngx-pagination {
  height: 30px; /* ajustar la altura según sea necesario */
  margin-bottom: 0;
}

.ngx-pagination a,
.ngx-pagination li
 {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
}

.paginate-menu-hamburguer ul {
  padding-top: 10px;
}

 .form-horizontal .ngui-auto-complete {
  width: 500px !important;
  position: absolute;
  left: 10px !important;
  top: 10px !important;
}
.form-horizontal .ngui-auto-complete ul {
  background-color: black;
} >



.btn-usb-rp{
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

 /*.md-drppicker td.in-range {
  background-color: #e1358626 !important;
//  border-color:  #da0268 !important;
 // color: #383737 !important;
} */
/*
.md-drppicker td.in-range {
  background-color:#e1358626  !important;

  border-radius: 0;
} */


 /*
 .md-drppicker td.available:hover, .md-drppicker th.available:hover
 {
  background-color: #d9026642 !important;

 }
 .md-drppicker td.in-range {
  background-color: ##e1358626 !important;
//  border-color:  #da0268 !important;
 // color: #383737 !important;
} */

/*
.md-drppicker td {
  color: #6c757d52!important;
}



.md-drppicker td.off, .md-drppicker td.off.end-date, .md-drppicker td.off.in-range, .md-drppicker td.off.start-date {
 // color: #999999 !important;
}

.md-drppicker td.in-range {
  background-color: #d9026642 !important;
//  border-color:  #da0268 !important;
  color: #383737 !important;
}


.md-drppicker td, .md-drppicker th {
   border: none !important;
} */


app-header.app-header {
  bottom: 23vh;
}

.font-poppins{
  font-family: 'Poppins' !important;
}

.ngui-auto-complete ul {max-height: 200px; overflow-y: auto;}

.status {
  font-family: 'Poppins', sans-serif !important;
  background-color: transparent;
  border: none !important;
  height: 26px;
  font-weight: 700 !important;
  font-size: 10px !important;
  padding: 7px !important;
  border-radius: 20px !important;
  text-transform: uppercase !important;
  display: inline-block !important;
  width: 92px !important;
  text-align: center !important;
}

// Estatus del itinerario

.itinerary-status-saved {  // 1
  background-color: $color-save-1 !important;
  color: $color-save-2 !important;
}

.itinerary-status-partial{ // '?'
  background-color: $color-partial-reserve-1  !important;
  color: $color-partial-reserve-2 !important;
}

.itinerary-status-reserved {  // 2
  background-color: $color-reserve-1 !important;
  color: $color-reserve-2 !important;
}

.itinerary-status-denied{ // 4
  background-color:$color-denegada-1 !important;
  color: $color-denegada-2 !important;
}

  .itinerary-status-cancelled { // 5
    background-color: $color-cancelada-1 !important;
    color: $color-cancelada-2 !important;
  }

.itinerary-status-locked { // 3
  background-color: $color-locked-1 !important;
  color: $color-locked-2 !important;
}

.itinerary-status-pay-usblick { // 6
  background-color: $color-pay-usblick-1 !important;
  color: $color-pay-usblick-2 !important;
}

.itinerary-status-pay-provider { // 7
  background-color: $color-pay-provider-1 !important;
  color: $color-pay-provider-2 !important;
}

.itinerary-status-billed { // 8
  background-color: $color-facturado-1 !important;
  color: $color-facturado-2 !important;
}

.itinerary-status-pending-approve-pay { // 9
  background-color: $color-pending-approve-pay-1 !important;
  color: $color-pending-approve-pay-2 !important;
}

.itinerary-status-paid { // '?'
  background-color: #0f347d !important;
  color: #ffffff !important;
}

.itinerary-status-completed { // 10
  background-color: $color-completed-1 !important;
  color: $color-completed-2 !important;
}

// FIN ESTATUS ITINERARIO

// Estatus del Pproducto

//--- SAVED

.product-status-saved {  //
  background-color: $color-save-1 !important;
  color: $color-save-2 !important;
}

.icon-status-saved{  // 1
  fill: $color-save-1 !important;
}

.background-icon-status-saved{
  background-color: $color-save-2 !important;
}

.border-status-saved{  // 1
  border: solid 1px $color-save-1 !important;
}
////////////////////////////

//--- RESERVED
.product-status-reserved {  // 2
  color: $color-reserve-2 !important;
  background-color: #00d096;
}

.icon-status-reserved{
  fill: $color-reserve-1 !important;
}

.background-icon-status-reserved{
  background-color: $color-reserve-2 !important;
}

.border-status-reserved{
  border: solid 1px $color-reserve-1 !important;
}
//////////////////////


//--- LOCKED
.product-status-locked {  // 3
  background-color: $color-locked-1 !important;
  color: $color-locked-2 !important;
}

.icon-status-locked{
  fill: $color-locked-1 !important;
}

.background-icon-status-locked{
  background-color: $color-locked-2 !important;
}

.border-status-locked{
  border: solid 1px $color-locked-1 !important;
}
////////////////////////



//--- DENIED
.product-status-denied {  // 4
  background-color: $color-denegada-1 !important;
  color: $color-denegada-2 !important;
}

.icon-status-denied{
  fill: $color-denegada-1 !important;
}

.background-icon-status-denied{
  background-color: $color-denegada-2 !important;
}

.border-status-denied{
  border: solid 1px $color-denegada-1 !important;
}
////////////////////////


//--- CANCELLED
.product-status-cancelled {  // 5
  background-color: $color-cancelada-1 !important;
  color: $color-cancelada-2 !important;
}

.icon-status-cancelled{
  fill: $color-cancelada-1 !important;
}

.background-icon-status-cancelled{
  background-color: $color-cancelada-2 !important;
}

.border-status-cancelled{
  border: solid 1px $color-cancelada-1 !important;
}
////////////////////////

//--- PAID AGENCY
.product-status-paid-agency {  // 6
  background-color: $color-pay-usblick-1 !important;
  color: $color-pay-usblick-2 !important;
}

.icon-status-paid-agency {
  fill: $color-pay-usblick-1 !important;
}

.background-icon-status-paid-agency{
  background-color: $color-pay-usblick-2 !important;
}

.border-status-paid-agency {
  border: solid 1px $color-pay-usblick-1 !important;
}
////////////////////////

//--- PAID PROVIDER
.product-status-paid-provider {  // 7
  background-color: $color-pay-provider-1 !important;
  color: $color-pay-provider-2 !important;
}

.icon-status-paid-provider {
  fill: $color-pay-provider-1 !important;
}

.background-icon-status-paid-provider{
  background-color: $color-pay-provider-2 !important;
}

.border-status-paid-provider {
  border: solid 1px $color-pay-provider-1 !important;
}

////////////////////////

//--- BILLED
.product-status-billed {  // 8
  background-color: $color-facturado-1 !important;
  color: $color-facturado-2 !important;
}

.icon-status-billed{
  fill: $color-facturado-1 !important;
}

.background-icon-status-billed{
  background-color: $color-facturado-2 !important;
}

.border-status-billed {
  border: solid 1px $color-facturado-1 !important;
}
////////////////////////

////////////////////////

//--- PENDING APPROVE PAY
.product-status-pending-approve-pay {  // 8
  background-color: $color-pending-approve-pay-1 !important;
  color: $color-pending-approve-pay-2 !important;
}

.icon-status-pending-approve-pay{
  fill: $color-pending-approve-pay-1 !important;
}

.background-icon-status-pending-approve-pay{
  background-color: $color-pending-approve-pay-2 !important;
}

.border-status-pending-approve-pay {
  border: solid 1px $color-completed-1 !important;
}
////////////////////////

////////////////////////

//--- COMPLETED
.product-status-completed {  // 8
  background-color: $color-completed-1 !important;
  color: $color-completed-2 !important;
}

.icon-status-completed{
  fill: $color-completed-1 !important;
}

.background-icon-status-completed{
  background-color: $color-completed-2 !important;
}

.border-status-completed {
  border: solid 1px $color-completed-1 !important;
}
////////////////////////

.btn-dark {
  background-color: #272c42 !important;
}

.panel-group .panel .panel-heading {
  background-color: white !important;
  color: #fff !important;
  border: 0 !important;
  padding: 0px !important;

}

.panel-group .panel .card {
  border: 0 !important;
}

.panel-body.card-block.card-body {
  background-color: #f6f9fa;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: .5px solid #e0e0e0;
  margin-top: -4px;
}

.client-modal .panel-body.card-block.card-body {
  background-color: white !important;
  border: none !important;
}

//Para evitar doble scroll en modal show

.modal {
  overflow: auto !important;
}
.custom-toast{
  margin-top: 100px;
}

.btn-usblick {
  border: none !important;
}

.btn-usblick:hover, .btn-usblick:hover.active, .btn-usblick:hover:active {
  opacity: 1 !important;
  border: none !important;
}
.btn-usblick:disabled, .btn-usblick:disabled:hover {
  color: $white !important;
  opacity: 1 !important;
  font-size: 14px  !important;
  font-weight: 600  !important;
  font-family: 'Poppins', sans-serif  !important;
  border: none !important;
}
.btn-usblick-pink:hover, .btn-usblick-pink.active, .btn-usblick-pink:active {
  color: $white !important;
  opacity: 1 !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  border: none !important;
}
.btn-usblick-pink {
  color: $white !important;
  opacity: 1 !important;
  border: none !important;
}
.btn-usblick-pink:disabled, .btn-usblick-pink:disabled:hover,
.btn-usblick-pink:hover {
  color: $white !important;
  opacity: 1 !important;
  border: none !important;
}
.btn-ligth-gray {
  background: #ffffff;
  color: #49505c !important;
  border: #a9a9a9 solid !important;
  border-width: 1px !important;
  margin: 0;
  padding: 8px 12px;
  }
.btn-ligth-gray:focus, .btn-ligth-gray:hover, .btn-ligth-gray.focus {
  background: #00E2A0 !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  border: #00E2A0 solid !important;
}
.btn-gray {
  float: right;
  background: #49505c;
  color: #9a9a9a;
  margin: 0;
  padding: 8px 12px;
  }
  .btn-green{
    background-color: #00d096 !important;
    border: #00d096 solid !important;
    height: 26px;
    padding-top: 0px !important;
    width: 88% !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    color: #ffff !important;
    font-weight: 800 !important;
  }

.btn:hover{
  cursor: pointer !important;
}

.btn-outline-danger {
  border: #a9a9a9 solid !important;
  border-width: 1px !important;
  color: #000 !important;
}

.btn-outline-danger:disabled, .btn-outline-danger:disabled:hover {
  color: #fff !important;
  border: none !important;
}
.btn-outline-danger:hover, btn-gray:hover {
  color: #fff !important;
  border: $red solid !important;
  border-width: 1px !important;
}

.btn-outline-pink {
  border: $red  solid !important;
  border-width: 1px !important;
  color: $red !important;
  background: none;
}

.btn-outline-pink:disabled, .btn-outline-pink:disabled:hover {
  border: $red  solid !important;
  border-width: 1px !important;
  color: $red !important;
  background: none;
}
.btn-outline-pink:hover, btn-gray:hover {
  color: #fff !important;
  border: $red solid !important;
  background: $red;
  border-width: 1px !important;
}

.btn-outline-darkblue {
  border: $dark-blue  solid !important;
  border-width: 1px !important;
  color: $dark-blue !important;
  background: none;
}

.btn-outline-darkblue:disabled, .btn-outline-darkblue:disabled:hover {
  border: $dark-blue  solid !important;
  border-width: 1px !important;
  color: $dark-blue !important;
  background: none;
}
.btn-outline-darkblue:hover, btn-gray:hover {
  color: #fff !important;
  border: $dark-blue solid !important;
  background: $dark-blue;
  border-width: 1px !important;
}

#dsny-room{
  .btn-select{
    border: none;
    justify-content: center !important;
    text-align: center !important;
  }
  .icon-selected{
    color: #00E2A0 !important;
  }
  .icon{
    color: #032541 !important;
  }
}

.toastr{
  position: relative !important;
  z-index: 1500 !important;
  margin-top: 100px !important;
}
#dsny{
  .card-header {
    padding: 0;
    height: 30px;
    margin-bottom: 0;
    background-color: #032541 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
  .bottom-number-dsny {
    color: #032541;
    position: relative;
    top: -15px;
    left: 10px;
    margin-right: 17px !important;
    border: #032541 1px solid;
    font-size: 1.5em;
    background-color: #ffffff;
    float: left;
    text-align: center;
    border-radius: 50%;
    padding: 0 10px;
    margin: 0;
    .icon{
      color: #032541 !important;
    }
    .icon-selected{
      color: #00E2A0 !important;
    }
    .cat{
      margin-left: 17px !important;
    }
    .btn-outline-primary:hover,.btn-outline-primary:active {
      color: #fff !important;
      background-color: #032541 !important;
      border-color: #032541 !important;
    }

    .btn-outline-primary{
        color: #032541 !important;
        background-color: transparent !important;
        background-image: none !important;
        border-color: #032541 !important;
    }
    option{
      background-color: #032541 !important;
      color: #032541 !important;
    }
   /* .dropdown-item {
      display: block !important;
      width: 100% !important;
      padding: .25rem 1.5rem !important;
      clear: both !important;
      font-weight: 400 !important;
      color: #032541 !important;
      text-align: inherit !important;
      white-space: nowrap !important;
      background-color: transparent !important;
      border: 0;
  }
  .dropdown-item:hover {
    background-color: #032541 !important;
    color: #fff !important;
  }*/
}
}
.btn-outline-danger:active,
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  border-width: 1px !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.btn-usb {
  background-color: $red !important;
  color: #fff !important;
  border: none;
  border-radius: 2px;
  width: 100%;
  opacity: 1 !important;
}

.btn-usb-rp {
 text-align: left;
}

.btn-usb:hover, .btn-usb:disabled {
  background-color: #00E2A0 !important;
  color: $white !important;
  border: none;
  opacity: 1 !important;
}

.error-label {
  color: $red !important;
  font-size: 9pt;
}

.terms {
  color: $red !important;
  font-family: "Lato-Regular";
}

#search-box {
  top: 150px;

  .nav-link span {
    color: #fff;
  }

  .nav-link span.nav-link.active {
    // color: #5cc999;
  }
  a.nav-link.active span {
    color: #5cc999;
  }



  a.nav-link.active, a.nav-link:hover, .nav-tabs:hover .nav-link:focus, a.nav-link:hover span {
    color: #5cc999;
  }

  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: transparent;
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #5cc999;
    background-color: transparent;
    border-color: transparent;
    width: 120px;
  }

  ul.nav-tabs, ul .nav-tabs {
    justify-content: space-evenly;
    border-bottom: 1px solid white;
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px;

  }

  a:active, a:hover{
    color: #5cc999;
  }

  *|*:link {
    display: flex;
    justify-content: center;
    width: 120px;
    color: #fff;
  }

/*
  .form-control{
    padding: 0.575rem 0.75rem !important;
  }


  .btn{
    padding: 0.575rem 0.75rem !important;
  }
    */



  select.form-control {

    &:focus::-ms-value {
      // Suppress the nested default white text on blue background highlight given to
      // the selected option text when the (still closed) <select> receives focus
      // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
      // match the appearance of the native widget.
      // See https://github.com/twbs/bootstrap/issues/19398.
      color: #000;
      background-color: #ccc;
    }
  }


}

select.form-control {

  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: #000;
    background-color: #ccc;
  }
}

#cruiser, #pthemes, #transport, #disney, #rentacar, #packages {
  span:hover {
    color: #000;
}
  .ng-select.custom {
    display: block;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: #000;
    border-radius: 0.25rem;

  }
  .ng-option.marked.selected {
    background-color: #ccc !important;
    color: #000 !important;
  }
  .ng-select-dropdown-outer .ng-select-dropdown .ng-option.selected {
    background-color: #ccc !important;
    color: #000;
  }
  .ng-select-dropdown-outer .ng-select-dropdown .ng-option.marked {
    background-color: #ccc !important;
    color: #333;
}
  .ng-select .ng-control .ng-value-container .ng-input > input {
    background-color: transparent !important;
    color: #000 !important;
  }
  .ng-select.custom .ng-control.custom  {
    min-height: 0px;
    border-radius: 0;
  }
  .ng-select .ng-control .ng-value-container .ng-placeholder {
    color: #333;
}
.ng-select.ng-single .ng-control .ng-value-container {
  height: 48px;
}
}
.ngui-auto-complete-wrapper {
  width: 100% !important;
  margin-top: 0 !important;
  padding: 0 !important;
  color: #49505c;
  font-size: 0.95rem;
}
.section-topextend {
  .ngui-auto-complete {
    color: #49505c;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .ngui-auto-complete ul {
    border-radius: 0.15rem !important;
    border-color: rgba($black,.1) !important;
    margin: 0 !important;
    padding: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
  .ngui-auto-complete ul li {
    height: 30px !important;
    color: #49505c;
    vertical-align: middle !important;
    align-items: center;
    padding-top: 3px;
    border-color: rgba($black,.6) !important;
  }
}
#modalrooms {
  //overflow-y: hidden !important;

  .br-fontawesome .br-selected:after {
    color: #ff9c00 !important;
    font-size: 14px;
}

.br-fontawesome .br-unit:after {
  font-size: 14px;
}

  .br {
    position: relative;
    margin: 0 !important;
  }
  .br-fontawesome-o .br-unit {
  align-items:center
  }
  .br-fontawesome-o .br-unit {
  font:normal normal normal 20px/1 FontAwesome;
  margin-right:1px
  }
.br-fontawesome-o .br-unit:after {
  content:'\e92c';
  font-family: 'icomoon';
  color:#00a680 !important;
  font-size: 1.1rem !important;
  }
  .br-fontawesome-o .br-selected:after {
    content:'\e92c';
    font-family: 'icomoon';
    color:#00a680 !important;
  }
  .br-fontawesome-o .br-active:after {
    content:'\e92d';
    font-family: 'icomoon';
    color:#00a680 !important;
  }
  .br-fontawesome-o .br-fraction:after {
    content:'\e92e';
    font-family: 'icomoon';
    color:#00a680 !important;
  }

}
tabset.tabcontainer {
  ul.nav-tabs, ul .nav-tabs {
    justify-content: space-between;
    border-bottom: 1px solid #f1f0f0 !important;
    border-radius: 0% !important;
    width: 100% !important;
    background:  #f1f0f0;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #00d096;
    background: #272c42 !important;
    border-bottom: 1px solid #f1f0f0 !important;
    border-radius: 0% !important;
    border: none !important;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
  }

  ul li a {
    color: #272c42;
    background:  #f1f0f0;
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
    width: 100% !important;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
  }
  ul li.nav.item   {
    margin: 0;
    text-align: center;
    border: none;
    border-radius: 0%;
    width: 100% !important;;
    margin: 0 !important;;
    padding: 0 !important;;
  }
  .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-right: 1px solid #f1f0f0 !important;;
    border-left:  1px solid #f1f0f0 !important;;
    background: #272c42 !important;
    color: #00d096 !important;
    width: 100% !important;
  }

  .nav-tabs .nav-link, .nav-link:active {
    border: none;
    border-radius: 0rem;
    // width: 16vh;
    // max-width: 20vh;
    // min-width: 13vh;
    text-align: center;

  }
  ul .nav-tabs .nav-item, .nav-tabs .nav-item {
    margin-bottom: -1px;
    width: 120px;
  }


  }
  .capitalize {
    text-transform: capitalize !important;
  }

#modalrooms {
  tabset.tabcontainer {
    ul.nav-tabs, ul .nav-tabs {
      justify-content: start !important;
    }
  }
}

.advanced-pie-legend .total-value {
  font-size: 18px !important;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
  font-size: 15px !important;
  margin-top: -1px !important;

}

div.item-percent {
  font-size: 15px !important;
}

.item-color {
  height: 34px !important;
}

.modal-lg {
  max-width: 900px !important;
}

.usb-itinerary-pdf  {
  display:none;
}

.icon-calendar {
  background: url(/./assets/img/app/icon-calendar.svg) no-repeat center;
  display: block;
  height: 19px;
  width: 25px;
}

.icon-cafe {
  background: url(/./assets/img/app/icon-cafe.svg) no-repeat center;
  display: block;
  height: 19px;
  width: 25px;
  border-radius: 0px !important;
  padding-right: 35px !important;
}

.icon-descuento {
  background: url(/./assets/img/app/icon-descuento.svg) no-repeat center;
  display: block;
  height: 24px;
  width: 34px;
}

.icon-medal {
  background: url(/./assets/img/app/little-icons/badge-bks.png) no-repeat center;
  display: block;
  height: 29px;
  width: 23px;
}

.icon-radio {
  background: url(/./assets/img/app/icon-radio.svg) no-repeat center;
  display: block;
  height: 19px;
  width: 25px;
  border-radius: 0px !important;
  padding-right: 35px !important;
}

.icon-tv {
  background: url(/./assets/img/app/icon-tv.svg) no-repeat center;
  display: block;
  height: 19px;
  width: 25px;
  border-radius: 0px !important;
  padding-right: 35px !important;
}

.icon-video-1 {
  background: url(/./assets/img/app/icon-video-1.svg) no-repeat center;
  display: block;
  height: 19px;
  width: 25px;
  // border-radius: 0px !important;
  // padding-right: 35px !important;
}

.icon-video-2 {
  background: url(/./assets/img/app/icon-video-2.svg) no-repeat center;
  display: block;
  height: 19px;
  width: 30px;
  // border-radius: 0px !important;
  // padding-right: 35px !important;
}

.icon-view {
  background: url(/./assets/img/app/icon-view.svg) no-repeat center;
  display: block;
  height: 19px;
  width: 25px;
  // border-radius: 0px !important;
  // padding-right: 35px !important;
}
.includes{
  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
  border: none !important;
  height: 26px;
  border: 1px solid #00d096 !important;
  border-radius: 4px !important;
  p{
      color: #00d096;
      font-weight: 600 !important;
      font-size: 12px !important;
  }
}
.icon-wifi {
  background: url(/./assets/img/app/icon-wifi.svg) no-repeat center;
  display: block;
  height: 19px;
  width: 25px;
  border-radius: 0px !important;
  padding-right: 35px !important;
}

.icon-maleta {
  background: url(/./assets/img/app/icon-maleta.svg) no-repeat center;
  display: block;
  height: 19px;
  width: 25px;

  border-radius: 0px !important;
  padding-right: 35px !important;
}

.icon-maleta2 {
  background: url(/./assets/img/app/icon-maleta2.svg) no-repeat center;
  display: block;
  height: 19px;
  width: 25px;

  border-radius: 0px !important;
  padding-right: 26px !important;
}

.icon-user2 {
  background: url(/./assets/img/app/icon-user2.svg) no-repeat center;
  display: block;
  height: 20px;
  width: 17px;

  border-radius: 0px !important;
  padding-right: 23px !important;
}

.border-right {
  border-right: 1px solid #757880 !important;
}

.border-left {
  border-left: 1px solid #757880 !important;
}

.icon-time {
  background: url(/./assets/img/app/icon-time.svg) no-repeat center;
  display: block;
  height: 22px;
  width: 26px;
}


.icon-speak {
  background: url(/./assets/img/app/icon-speak.svg) no-repeat center;
  display: block;
  height: 20px;
  width: 22px;
}

.icon-speak {
  background: url(/./assets/img/app/icon-speak.svg) no-repeat center;
  display: block;
  height: 20px;
  width: 22px;
}

.icon-transport {
  background: url(/./assets/img/app/icon-transport.svg) no-repeat center;
  display: block;
  height: 20px;
  width: 17px;
}
.icon-duration {
  background: url(/./assets/img/app/icon-duration.svg) no-repeat center;
  display: block;
  height: 20px;
  width: 21px;
}

.numberDay {
  background-color:#d60159 ;
  color: white;
  font-size: 14px !important;
  font-weight: 800  !important;
  font-family: 'Lato', sans-serif  !important;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  // margin-left: -45px   !important;
}


.font-days {
  color: #d60159;
  font-size: 14px !important;
  font-weight: 800  !important;
  font-family: 'Lato', sans-serif  !important;
}

.font-view {
  color: #000000;
  font-size: 11.3px  !important;
}

.font-green{
  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
  border: none !important;
  height: 26px;
 // padding-top: 0px !important;
  width: 88% !important;
  color: #00d096;
  font-weight: bold !important;
  font-size: 12px !important;
  border: 1px solid #00d096 !important;
  padding: 4px !important;
  border-radius: 4px !important;
}

.font-green-sb{
  font-family: 'Poppins', sans-serif;
  background-color: transparent !important;
  border: none !important;
  height: 26px;
  margin-top: -4px !important;
  width: 88% !important;
  color: #00d096;
  font-weight: 700 !important;
  font-size: 13px !important;
}

.img-position {
  padding: 50px  !important;
  padding-top: 10px !important;
}

.location {
  font-family: 'Poppins', sans-serif !important;
  color: #00d096;
  font-weight: 800 !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
}

.description-location {
  font-family: 'Lato', sans-serif !important;
  color: #000000;
  font-weight: 800 !important;
  font-size: 14px !important;

}
.font-desc{
    font-family: 'Lato', sans-serif !important;
    font-size: 18px !important;
    color: #000000 !important;
    font-weight: 800 !important;
 }

 .font-desc-2{
  font-family: 'Lato', sans-serif  !important;
  font-size: 12 !important;
  color: #383737 !important;
  font-weight: 500 !important;
}

.font-point {
  font-family: 'Poppins', sans-serif !important;
  font-size: 12px !important;
  color: #fbd05a;
  font-weight: 600 !important;
}
.continue {
  background-color: #d8d8d8  !important;

  width: 100% !important;;

  border-radius: 0 5px 5px 0 !important;
  .search-price {
    font-size: 29px !important;
    margin-top: -7px !important;
    margin-bottom: -7px !important;
    text-align: right !important;
    color: #272727  !important;
    font-weight: 700 !important;
    text-align: left !important;
    font-family: "Open Sans" !important
  }
  .text-price {
    font-size: 18px !important;
    color: #272727 !important;
    font-weight: 700 !important;
    text-align: left !important;
    font-family: "Open Sans" !important;
}

  .usd {
    text-align: right !important;
    color: #272727  !important;
    font-size: 20px  !important;
    font-weight: 700 !important;
    font-family: "Open Sans" !important;
  }

}
.separator {

  border-radius: 0px !important;
  padding-left: 3px !important;
  height: 27px !important;
}
.font-number {
  font-family: 'Poppins', sans-serif !important;
  font-size: 19px !important;
  color: #272727 !important;
  font-weight: 600 !important;
  padding-left: 10px;
}


.address-resumen-t, .address-resumen-t:active, .address-resumen-t:hover {
  color: #26d39e !important;
  font-weight: 800 !important;
  font-family: 'Poppins', sans-serif !important;
}

.card-info {
  font-family: 'Poppins', sans-serif !important;
  font-size: 13px !important;
  text-align: left !important;
}

.card-info-title {
  font-weight:  800 !important;
}

.card-info-subtitle {
  font-weight:  700 !important;
}


.total-select{
  color: #00cb92 !important;
  font-weight: 800 !important;
  text-transform: none !important;
}

.usd-select{
  color: #272727 !important;
  font-weight: 800 !important;
}

.description-modal{
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: #070412 ;
  font-weight: 300 ;
  text-transform: none;
}
.price-bold{
  font-weight: 800 !important;
}


// scroll para los input
// .scroll /deep/ .ngui-auto-complete ul {
//   // height: 150px !important;
//   height: auto !important;
//   max-height: 150px !important;
//   overflow-y: auto !important;
// }


.background-modal{
  padding-right: 80px;
  padding-left: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #848484;
}

.background-modal .mat-dialog-container {
  padding: 0px !important;
  background: #848484 !important;
  box-shadow: unset !important;
}

.input-search-box{
  height: 49px !important;
}



// @font-face {
//   font-family: 'Material Icons';
//   font-style: normal;
//   font-weight: 400;
//   src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
//   src: local('Material Icons'),
//     local('MaterialIcons-Regular'),
//     url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
//     url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
//     url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
// }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

}

// carousel
.carousel-control-prev, .carousel-control-next {
  visibility: hidden;
}


// toats

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

.expansion-panel-images .mat-expansion-panel-body {
  padding: 0 !important;
}

.owl-next img, .owl-prev img {
  width: 40px;
}

// PHOTOS MODAL HOTEL

.photos-modal-hotel .shortcuts .owl-carousel.owl-theme.owl-loaded.owl-responsive .owl-nav.ng-star-inserted .owl-next,
.photos-modal-hotel .shortcuts .owl-carousel.owl-theme.owl-loaded.owl-responsive .owl-nav.ng-star-inserted .owl-prev {
  background-color: transparent !important;
  top: 45% !important;
}

.photos-modal-hotel .owl-theme .owl-nav {
  background: transparent !important
}

.photos-modal-hotel .shortcuts .owl-next {
  right: -41px !important;
}

.photos-modal-hotel .shortcuts .owl-prev {
  left: 13px !important;
}

// MODAL HOTEL

.carousel-modal-hotel .shortcuts .owl-carousel.owl-theme.owl-loaded.owl-responsive .owl-nav.ng-star-inserted .owl-next,
.carousel-modal-hotel .shortcuts .owl-carousel.owl-theme.owl-loaded.owl-responsive .owl-nav.ng-star-inserted .owl-prev {
  background-color: transparent !important;
  top: 48% !important;
}

.carousel-modal-hotel .owl-theme .owl-nav {
  background: transparent !important
}

.carousel-modal-hotel .shortcuts .owl-next {

  right: -40px !important;
}

.carousel-modal-hotel .shortcuts .owl-prev {

  left: 14px !important;
}

// SHORTCUTS LINK

  .shortcuts .owl-next, .shortcuts .owl-prev {
    position: absolute !important;
    top: 55% !important;
    height: 100px;
    background-color: #ffffff !important;
    color: #000000 !important;
  }

  .shortcuts .owl-next {
    font-size: 50px !important;
    right: -58px;
    transform: translate(-50%, -50%);
  }

  .shortcuts .owl-next .bi::before {
    margin-right: -12px;
  }

  .shortcuts .owl-prev {
    font-size: 50px !important;
    left: -15px;
    transform: translate(-50%, -50%);

  }

  .form-control.no-spinner[type=number]::-webkit-inner-spin-button,
  .form-control.no-spinner[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

// DESTINATION-BIG

.destination-big .owl-next, .destination-big .owl-prev {
  position: absolute !important;
  top: 55% !important;
  height: 100px;
  background: none!important;
  color: #000000 !important;
}

  .destination-big .owl-next {
    font-size: 50px !important;
    right: -1.5em;
    transform: translate(-50%, -50%);
  }

  .destination-big .owl-prev {
    font-size: 50px !important;
    left: .75em;
    transform: translate(-50%, -50%);
  }

  .destination-big .owl-next .bi::before {
    background-color: white;
    width: 100px;
    height: 100px;
    border-radius: 50rem;
    padding-top: .5em;
    padding-right: .5em;
  }

  .destination-big .owl-prev .bi::before {
    background-color: white;
    width: 100px;
    height: 100px;
    border-radius: 50rem;
    padding-top: .5em;
    padding-left: .65em;
  }

.bs-datepicker {
    border-radius: 15px !important;
  }

div.bs-datepicker-container {
    font-family: 'Poppins', sans-serif;
    padding: 0 !important;
    padding-left: 10px !important;
    padding-right: 15px !important;
    padding-bottom: 20px !important;
  }

bs-datepicker-navigation-view {
  display: flex;
  justify-content: space-between;
}

bs-datepicker-navigation-view button {
  color: black !important;
}

.theme-green .bs-datepicker-head {
  background-color: white !important;
}

.bs-datepicker-body {
  border: none !important;
}

.bs-datepicker-body table.days.weeks tbody tr td.week span {
  display: none;
}

.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: rgba(67, 255, 202, 0.411)!important;
}

.theme-green .bs-datepicker-body table td span.selected {
  background-color: rgb(0, 211, 151) !important;

}

canvas .chartjs-render-monitor {
  width: 300px !important;
  height: 150px !important;
}

.custom-container .mat-dialog-container {
  border-radius: 0px !important;
}

mat-dialog-container {
  border-radius: 30px !important;
}


.mat-dialog-container {
  overflow-x: hidden !important;
  padding: 25px 40px !important;
}

.custom-dialog .mat-dialog-container {
  padding: 0 !important;
}

.modal-edit-search .modal-content {
  border-radius: 30px;
}


.dailyPrices-owl-carousel .owl-item {
  width: 103px !important;
}

.dailyPrices-owl-carousel .owl-carousel {
  width:514px !important;
}

.dailyPrices-owl-carousel .owl-theme .owl-nav {
  margin-top: -80px !important;
}

.dailyPrices-owl-carousel .owl-prev {
position:absolute;
left: -60px;
background: white !important;
}

.dailyPrices-owl-carousel .owl-next {
  position:absolute;
  right: -60px;
  background: white !important;
}

// .dailyPrices-owl-carousel .owl-theme .owl-nav [class*='owl-'] {

// }

.tooltipItinerary bs-tooltip-container {
  z-index: 0 !important;
}

.tooltipItinerary .tooltip-arrow {
  opacity: 0;
}

.tooltipItinerary .tooltip-inner {
  padding: 8px 15px;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.325);
  background-color: white !important;
}


